module.exports = {
  "mode": "compressed",
  "staticDomainPrefix": "//static.hsappstatic.net",
  "bender": {
    "depVersions": {
      "conversations-embed": "static-1.20387",
      "browserslist-config-hubspot": "static-1.133",
      "classnames": "static-2.10",
      "conversations-internal-schema": "static-1.9689",
      "csstype": "static-1.8",
      "cssUtils": "static-1.314",
      "enviro": "static-4.260",
      "floating-ui": "static-1.15",
      "foundations-assets": "static-1.1774",
      "foundations-components": "static-1.3192",
      "foundations-theming": "static-1.878",
      "framer-motion": "static-1.25",
      "head-dlb": "static-1.2040",
      "HeadJS": "static-2.491",
      "hoist-non-react-statics": "static-3.9",
      "hs-story-utils": "static-1.5796",
      "hs-test-utils": "static-1.4848",
      "hubspot-url-utils": "static-1.1566",
      "HubStyle": "static-2.8704",
      "HubStyleTokens": "static-2.8394",
      "I18n": "static-7.1251",
      "i18n-data": "static-1.183",
      "icons": "static-2.582",
      "immutable": "static-2.19",
      "isMobile": "static-1.7",
      "jasmine": "static-4.1396",
      "jasmine-runner": "static-1.3306",
      "metrics-js": "static-1.6327",
      "moment-timezone": "static-5.17",
      "PortalIdParser": "static-2.239",
      "quartz": "static-1.3500",
      "quartz-core-utils": "static-1.367",
      "react": "static-7.133",
      "react-aria": "static-1.7",
      "react-dom": "static-7.85",
      "react-input-autosize": "static-2.17",
      "react-select-plus": "static-1.65",
      "react-utils": "static-2.2761",
      "react-virtualized": "static-2.62",
      "sassPrefix": "static-1.116",
      "sinon": "static-1.9",
      "styled-components": "static-2.40",
      "StyleGuideUI": "static-3.421",
      "tanstack-table": "static-1.10",
      "testing-library": "static-1.109",
      "transmute": "static-2.29",
      "ui-fonts": "static-1.326",
      "ui-images": "static-2.745",
      "UIComponents": "static-3.5344",
      "webpack-env": "static-1.41",
      "hs-promise-rejection-tracking": "static-1.2371",
      "raven": "static-3.2477",
      "raven-hubspot": "static-1.2784",
      "bend-plugin-trellis-migration": "static-1.529",
      "moment": "static-3.26",
      "hub-http": "static-1.2732",
      "hub-http-janus": "static-1.557",
      "hub-http-rxjs": "static-1.523",
      "msw": "static-1.39",
      "react-redux": "static-7.16",
      "redux": "static-4.16",
      "redux-mock-store": "static-1.8",
      "redux-thunk": "static-2.17",
      "rxjs": "static-5.10"
    },
    "depPathPrefixes": {
      "conversations-embed": "/conversations-embed/static-1.20387",
      "browserslist-config-hubspot": "/browserslist-config-hubspot/static-1.133",
      "classnames": "/classnames/static-2.10",
      "conversations-internal-schema": "/conversations-internal-schema/static-1.9689",
      "csstype": "/csstype/static-1.8",
      "cssUtils": "/cssUtils/static-1.314",
      "enviro": "/enviro/static-4.260",
      "floating-ui": "/floating-ui/static-1.15",
      "foundations-assets": "/foundations-assets/static-1.1774",
      "foundations-components": "/foundations-components/static-1.3192",
      "foundations-theming": "/foundations-theming/static-1.878",
      "framer-motion": "/framer-motion/static-1.25",
      "head-dlb": "/head-dlb/static-1.2040",
      "HeadJS": "/HeadJS/static-2.491",
      "hoist-non-react-statics": "/hoist-non-react-statics/static-3.9",
      "hs-story-utils": "/hs-story-utils/static-1.5796",
      "hs-test-utils": "/hs-test-utils/static-1.4848",
      "hubspot-url-utils": "/hubspot-url-utils/static-1.1566",
      "HubStyle": "/HubStyle/static-2.8704",
      "HubStyleTokens": "/HubStyleTokens/static-2.8394",
      "I18n": "/I18n/static-7.1251",
      "i18n-data": "/i18n-data/static-1.183",
      "icons": "/icons/static-2.582",
      "immutable": "/immutable/static-2.19",
      "isMobile": "/isMobile/static-1.7",
      "jasmine": "/jasmine/static-4.1396",
      "jasmine-runner": "/jasmine-runner/static-1.3306",
      "metrics-js": "/metrics-js/static-1.6327",
      "moment-timezone": "/moment-timezone/static-5.17",
      "PortalIdParser": "/PortalIdParser/static-2.239",
      "quartz": "/quartz/static-1.3500",
      "quartz-core-utils": "/quartz-core-utils/static-1.367",
      "react": "/react/static-7.133",
      "react-aria": "/react-aria/static-1.7",
      "react-dom": "/react-dom/static-7.85",
      "react-input-autosize": "/react-input-autosize/static-2.17",
      "react-select-plus": "/react-select-plus/static-1.65",
      "react-utils": "/react-utils/static-2.2761",
      "react-virtualized": "/react-virtualized/static-2.62",
      "sassPrefix": "/sassPrefix/static-1.116",
      "sinon": "/sinon/static-1.9",
      "styled-components": "/styled-components/static-2.40",
      "StyleGuideUI": "/StyleGuideUI/static-3.421",
      "tanstack-table": "/tanstack-table/static-1.10",
      "testing-library": "/testing-library/static-1.109",
      "transmute": "/transmute/static-2.29",
      "ui-fonts": "/ui-fonts/static-1.326",
      "ui-images": "/ui-images/static-2.745",
      "UIComponents": "/UIComponents/static-3.5344",
      "webpack-env": "/webpack-env/static-1.41",
      "hs-promise-rejection-tracking": "/hs-promise-rejection-tracking/static-1.2371",
      "raven": "/raven/static-3.2477",
      "raven-hubspot": "/raven-hubspot/static-1.2784",
      "bend-plugin-trellis-migration": "/bend-plugin-trellis-migration/static-1.529",
      "moment": "/moment/static-3.26",
      "hub-http": "/hub-http/static-1.2732",
      "hub-http-janus": "/hub-http-janus/static-1.557",
      "hub-http-rxjs": "/hub-http-rxjs/static-1.523",
      "msw": "/msw/static-1.39",
      "react-redux": "/react-redux/static-7.16",
      "redux": "/redux/static-4.16",
      "redux-mock-store": "/redux-mock-store/static-1.8",
      "redux-thunk": "/redux-thunk/static-2.17",
      "rxjs": "/rxjs/static-5.10"
    },
    "project": "conversations-embed",
    "staticDomain": "//static.hsappstatic.net",
    "staticDomainPrefix": "//static.hsappstatic.net"
  }
};